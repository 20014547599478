import React, { FunctionComponent, useContext, useEffect, useState } from 'react'
import { AppBar, Fab, makeStyles } from '@material-ui/core'
import { GridItem } from '@meowwolf/mw-denver-react'
import SyncIcon from '../../assets/icons/pair.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { GameStateContext } from '../../utils/FakeAssGamestate'
import { useCookies } from 'react-cookie'
import { COOKIE_NAMES } from '../../constants'

const height = 210

const useStyles = makeStyles((theme) => ({
  footer: {
    top: 'auto',
    bottom: 0,
    background: 'transparent',
    boxShadow: 'none',
    padding: '1rem',
  },
  actionSlider: {
    color: 'white',
    zIndex: 1000,
    height,
    bottom: 0,
    left: 0,
    right: 0,
    top: 'auto',
    position: 'fixed',
    transition: 'bottom 0.5s',
    background: theme.palette.info.main,
    overflow: 'hidden',
    borderTopLeftRadius: 50,
    borderTopRightRadius: 50,
    padding: '2rem 2rem 0',
    '&.closed': {
      bottom: `-${height}px`,
    },
    '& a': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: '1.25rem',
    },
  },
  actionSliderInner: {
    textAlign: 'center',
    maxWidth: 400,
    margin: '0 auto',
  },
  syncButton: {
    background: '#7989FF',
  },
}))

enum Position {
  Open,
  Closed,
}

let timeout: NodeJS.Timeout

const Footer: FunctionComponent = () => {
  const { isLoading: auth0IsLoading, loginWithRedirect, isAuthenticated } = useAuth0()
  const { gameState } = useContext(GameStateContext)
  const classes = useStyles()
  const [position, setPosition] = useState(Position.Closed)
  const [cookies, setCookie] = useCookies([COOKIE_NAMES.login_prompt])

  useEffect(() => {
    if (!cookies[COOKIE_NAMES.login_prompt]) {
      timeout = setTimeout(() => {
        setCookie(COOKIE_NAMES.login_prompt, true)
        setPosition(Position.Open)
      }, 500)
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [])
  /**
   * Don't show the footer if
   * - auth0 is loading
   * - user is authenticated
   * - gameState is not available
   */
  if (auth0IsLoading || !gameState || isAuthenticated) {
    return <></>
  }

  return (
    <>
      <AppBar position="fixed" className={classes.footer}>
        {position === Position.Closed && (
          <GridItem container justifyContent="flex-end" alignItems="center">
            <GridItem>
              <Fab
                className={classes.syncButton}
                color="default"
                aria-label="add"
                onClick={() => setPosition(Position.Open)}
              >
                <img src={SyncIcon} alt="Sync account" />
              </Fab>
            </GridItem>
          </GridItem>
        )}
        <div className={`${classes.actionSlider} ${position === Position.Open ? 'open' : 'closed'}`}>
          <div className={classes.actionSliderInner}>
            <h2>Sync MEMs to your Meow Wolf account?</h2>
            <GridItem container justifyContent="space-between">
              <GridItem>
                {/* Todo track this? */}
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault()
                    setPosition(Position.Closed)
                  }}
                >
                  No thanks
                </a>
              </GridItem>
              <GridItem>
                {/* Todo track syncing? */}
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault()
                    loginWithRedirect({
                      redirectUri: `${location.origin}?_id=${gameState.user.id}`,
                      fragment: `_id=${gameState.user.id}`,
                    })
                  }}
                >
                  Sync Now
                </a>
              </GridItem>
            </GridItem>
          </div>
        </div>
      </AppBar>
    </>
  )
}

export default Footer
