import { User } from '@auth0/auth0-spa-js'

interface Auth0EosData {
  den_eos_id?: string
}

export const getUserMeta = (user: User): Auth0EosData | null => user['https://meowwolf.com/eos'] || null

export const getUserEosID = (user: User): string | null => {
  const meta = getUserMeta(user)
  return (meta && meta.den_eos_id) || null
}
