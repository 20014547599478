import React from 'react'
import { Typography, makeStyles, Button, Grid } from '@material-ui/core'
import {
  BackgroundImage,
  ContainerWithNavBar,
  GridItem,
  HorizontalGrid,
  VerticalGrid,
  ViewportBox,
  Markdown,
} from '@meowwolf/mw-denver-react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { Post } from './RecallBoards'
import NavBar from '../common/NavBar'
import bgImgSrc from './assets/post-bg.png'
import postImgSrc from './assets/post-item-bg.png'
import MemoWidget from '../common/MemoWidget'

interface Props {
  article: Post
  onClose: () => void
  onClickNextPost?: () => void
  onClickPrevPost?: () => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'scroll',
  },
  title: {
    fontSize: '2.25rem',
    fontWeight: 800,
    lineHeight: '2.75rem',
    letterSpacing: '1px',
    marginTop: '2.25rem',
  },
  byLine: {
    fontSize: '1.5rem',
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
  },
  characterName: {
    fontWeight: 800,
  },
  navLinks: {
    textAlign: 'right',
    marginBottom: '4vh',
    marginTop: '2vh',
    lineHeight: '0rem',
  },
  chevron: {
    color: theme.palette.primary.main,
    fontSize: '3rem',
  },
  paragraph: {
    marginBottom: '2rem',
  },
  listingNavButton: {
    lineHeight: '1rem',
  },
}))

const RecallBoardsPost: React.FC<Props> = ({ article, onClose, onClickNextPost, onClickPrevPost }) => {
  const classes = useStyles()
  const iconImgSrc = article.iconImgSrc || postImgSrc

  const paragraphs = article.body.split('\n').map((p, idx) => (
    <div key={idx} className={classes.paragraph}>
      <Markdown>{p}</Markdown>
    </div>
  ))

  function _onClickNextPost(): void {
    onClickNextPost && onClickNextPost()
  }

  function _onClickPrevPost(): void {
    onClickPrevPost && onClickPrevPost()
  }

  return (
    <ViewportBox>
      <MemoWidget />
      <ContainerWithNavBar
        className={classes.root}
        bgImgSrc={bgImgSrc}
        navbar={() => <NavBar title="Recall Boards" onClickGoBack={onClose} goBackText=" " />}
      >
        <VerticalGrid>
          {/* Previous & Next */}
          <GridItem className={classes.navLinks}>
            <Grid xs={12} container justifyContent="space-between">
              {/* Previous */}
              <GridItem>
                {onClickPrevPost && (
                  <Button color="primary" onClick={_onClickPrevPost}>
                    <ChevronLeftIcon className={classes.chevron} />
                    &nbsp; <span className={classes.listingNavButton}>Previous Listing</span>
                  </Button>
                )}
              </GridItem>
              {/* Next */}
              <GridItem>
                {onClickNextPost && (
                  <Button color="primary" onClick={_onClickNextPost}>
                    &nbsp; <span className={classes.listingNavButton}>Next Listing</span>
                    <ChevronRightIcon className={classes.chevron} />
                  </Button>
                )}
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem flexGrow>
            <VerticalGrid>
              {/* Left-hand column */}
              <GridItem sm={12} md={6}>
                <HorizontalGrid alignItems="center">
                  {/* Image */}
                  <BackgroundImage src={iconImgSrc} width="390px" height="390px" />
                </HorizontalGrid>
              </GridItem>
              {/* Right-hand column */}
              <GridItem sm={12} md={6}>
                {/* Title */}
                <Typography variant="h1" className={classes.title} color="primary">
                  {article.title}
                </Typography>
                {/* Byline */}
                <Typography
                  variant="body2"
                  className={classes.byLine}
                  color="textPrimary"
                  style={{ fontSize: '1rem', marginTop: '0.5rem' }}
                >
                  Posted by <span className={classes.characterName}>{article.characterName}</span>
                </Typography>
                {/* Body */}
                {paragraphs}
              </GridItem>
              {/* Award */}
            </VerticalGrid>
          </GridItem>
        </VerticalGrid>
      </ContainerWithNavBar>
    </ViewportBox>
  )
}

export default RecallBoardsPost
