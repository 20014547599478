import React from 'react'
import { makeStyles } from '@material-ui/core'
import { GridItem } from '@meowwolf/mw-denver-react'

import { Avatar, Typography } from '@material-ui/core'

interface Props {
  characterAvatarSrc?: string
  characterName?: string
  color?: string
}

const useStyles = makeStyles({
  root: {
    marginTop: 10,
  },
  avatar: ({ color }: Props) => ({
    width: 180,
    height: 180,
    marginBottom: 36,
    borderRadius: '50%',
    border: `10px solid ${color || ''}`,
  }),
  characterName: {
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: '0.02rem',
    margin: 0,
    padding: 0,
  },
})

/**
 * Avatar image and name of the memory "rememberer".
 */
const MemoryInventorySideBarCharacter: React.FC<Props> = ({ characterAvatarSrc, characterName, color }) => {
  const classes = useStyles({ characterAvatarSrc, characterName, color })
  return (
    <div className={classes.root}>
      {/* Avatar */}
      {characterAvatarSrc && (
        <GridItem container justifyContent="center">
          <Avatar src={characterAvatarSrc} className={classes.avatar} />
        </GridItem>
      )}
      {/* Character */}
      {characterName && (
        <>
          <Typography variant="h3" align="center" style={{ margin: 0, padding: 0, lineHeight: '1rem' }}>
            CREATOR
          </Typography>
          <Typography variant="body1" align="center" className={classes.characterName}>
            {characterName}
          </Typography>
        </>
      )}
    </div>
  )
}

export default MemoryInventorySideBarCharacter
