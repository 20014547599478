import React, { useCallback, useContext } from 'react'
import hexRgb from 'hex-rgb'
import { BackgroundImage, GridItem, VerticalGrid, VerticallyCentered } from '@meowwolf/mw-denver-react'
import { GridProps, Typography, makeStyles, useTheme } from '@material-ui/core'
import { Post } from './RecallBoards'
import postBgImgSrc from './assets/post-item-bg.png'
import { LayoutContext } from '../../utils/LayoutProvider'

interface Props extends GridProps {
  post: Post
  onClickPost: (id: number) => void
}

const useStyles = makeStyles((theme) => {
  const { red, green, blue } = hexRgb(theme.palette.background.default)
  return {
    root: {
      height: 350,
      textAlign: 'center',
      padding: 0,
      margin: 0,
    },
    container: {
      boxShadow: `0px 0px 0px 12px rgba(${red}, ${green}, ${blue}, 0.6)`,
    },
    image: {
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
    },
    textContainer: {
      background: theme.palette.info.dark,
    },
    titleContainer: {
      padding: '1rem 0.5rem 0.5rem',
      height: 60,
      width: '100%',
    },
    title: {
      width: '100%',
      margin: 0,
      padding: 0,
    },
    titleHeading: {
      fontSize: '1.125rem',
      fontWeight: 800,
      lineHeight: '1.5rem',
      letterSpacing: '5%',
    },
    viewListing: {
      fontSize: '1rem',
      fontWeight: 800,
      lineHeight: '1.5rem',
      letterSpacing: '5%',
      textDecoration: 'underline',
      marginTop: '1rem',
      marginBottom: '2rem',
    },
  }
})

/**
 * A single cell in the 4-column recall boards grid.
 * This contains an icon and a title for each posting.
 */
const RecallBoardsGridItem: React.FC<Props> = ({ post, onClickPost, ...gridProps }) => {
  const { isMobile } = useContext(LayoutContext)
  const onClick = () => onClickPost(post.id)
  const classes = useStyles()
  const theme = useTheme()
  const bgImgSrc = post.iconImgSrc || postBgImgSrc

  // NOTE: this is super jenky, should be more breakpoint focused
  const truncate = useCallback(
    (text: string): string => (isMobile ? `${text.slice(0, 15).trim()}...` : text),
    [isMobile],
  )

  return (
    <GridItem xs={6} sm={4} md={3} {...gridProps} onClick={onClick} className={classes.root}>
      <BackgroundImage color={theme.palette.background.paper} className={classes.container}>
        {/* Image */}
        <BackgroundImage src={bgImgSrc} size="contain" position="top">
          <VerticalGrid>
            <GridItem flexGrow />
            <GridItem className={classes.textContainer}>
              {/* Title */}
              <GridItem className={classes.titleContainer}>
                <VerticallyCentered className={classes.title}>
                  <Typography variant="h4" className={classes.titleHeading} title={post.title} aria-label={post.title}>
                    {truncate(post.title)}
                  </Typography>
                </VerticallyCentered>
              </GridItem>
              {/* Byline */}
              <Typography variant="body1" color="primary" className={classes.viewListing}>
                View Listings
              </Typography>
            </GridItem>
          </VerticalGrid>
        </BackgroundImage>
      </BackgroundImage>
    </GridItem>
  )
}
export default RecallBoardsGridItem
