import React from 'react'
import { Node } from '@meowwolf/mw-denver-react'
import { makeStyles, Grid, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Cancel'
import MemoryInventorySideBarMedia from './MemoryInventory-SideBarMedia'
import MemoryInventorySideBarText from './MemoryInventory-SideBarText'
import MemoryInventorySideBarCharacter from './MemoryInventory-SideBarCharacter'
import { WorldName, WorldNodes } from './MemoryInventory'
import { closeButtonStyles } from '../../constants'

interface Props {
  activeNode?: Node
  isOpen: boolean
  worldNodes: WorldNodes
  activeWorld?: WorldName
  activeWorldIdx?: number
  setActiveNodeId: (id: string | undefined) => void
  onClose: () => void
}

const useStyles = makeStyles({
  root: {},
  crawlOutOfMyAssSidebar: {
    background: 'rgb(49, 43, 68)',
    position: 'absolute',
    bottom: 0,
    paddingTop: '36px',
    paddingBottom: '1rem',
  },
  closeButton: {
    ...closeButtonStyles,
  },
})
/**
 * Sidebar drawer that displays Memory/Mem information on the right side of the Memory Inventory nodal view.
 */
const MemoryInventorySideBar: React.FC<Props> = ({
  // isOpen,
  onClose,
  activeNode,
  // worldNodes,
  // activeWorld,
  // activeWorldIdx,
  // setActiveNodeId,
}) => {
  const classes = useStyles()

  if (!activeNode?.meta) {
    return <></>
  }

  const {
    mediaSrc,
    title,
    description,
    // characterAvatarSrc,
    characterName,
    type,
    englishCaptionsURL,
    spanishCaptionsURL,
  } = activeNode.meta

  return (
    <Grid container className={classes.crawlOutOfMyAssSidebar}>
      <Button className={classes.closeButton} onClick={() => onClose()}>
        <CloseIcon />
      </Button>
      <Grid item xs={12}>
        <Grid container alignItems="center">
          {type === 'mem' && (
            <MemoryInventorySideBarMedia
              mediaSrc={mediaSrc}
              type={type}
              color={activeNode.color}
              englishCaptionsURL={englishCaptionsURL}
              spanishCaptionsURL={spanishCaptionsURL}
            />
          )}
          {type === 'memory' && (
            <iframe
              src={`https://player.vimeo.com/video/${activeNode.meta.vimeoId}`}
              allow="autoplay; fullscreen;"
              allowFullScreen={true}
              style={{ border: 0, width: '100%' }}
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MemoryInventorySideBarText
          title={title}
          description={description}
          color={activeNode.color}
          recallCode={activeNode.meta.recallCode}
        />
      </Grid>
      <Grid container justifyContent="center" alignContent="center" xs={12}>
        <MemoryInventorySideBarCharacter characterName={characterName} color={activeNode.color} />
      </Grid>
    </Grid>
  )
}

export default MemoryInventorySideBar
