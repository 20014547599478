import React, { createContext, useState, useEffect, FunctionComponent, useRef } from 'react'
import queryString from 'query-string'
import { GameState, parseUserIntoGameState } from '@meowwolf/react-platform-connection'
import { matchPath, useHistory, useLocation } from 'react-router'
import { ALPHA_MAX_LENGTH } from '../constants'
import { ROUTE_NAMES } from './route-utils'

export interface GameStateI {
  gameState: GameState | null
  isLoading: boolean
  hasErrors: boolean
}

const defaultGameState: GameStateI = {
  isLoading: true,
  hasErrors: false,
  gameState: null,
}

export const GameStateContext = createContext<GameStateI>({
  isLoading: true,
  hasErrors: false,
  gameState: {
    projectCode: 'test',
    user: {
      id: 'test',
      identifiers: [],
      inventory: [],
      properties: [],
      state: [],
      structuredProperties: {
        OMEmployeeType: 'DramCorp',
        installationBoops: {},
        isBeingConsideredForTheResistance: false,
        omEmployeeLevel: 1,
        tags: [],
      },
    },
  },
})

const FakeAssGamestate: FunctionComponent = ({ children }) => {
  const [state, setState] = useState<GameStateI>(defaultGameState)
  const fakeState = useRef<GameState | null>(null)
  const history = useHistory()
  const location = useLocation()

  const handleError = () => {
    const title = 'Error Processing ID'
    const description = 'Your ID is not available at this time.'
    // no data redirect to home
    history.push(`${ROUTE_NAMES.ROOT}?error=${title}&error_description=${description}`)
    setState({
      isLoading: false,
      hasErrors: true,
      gameState: null,
    })
  }

  useEffect(() => {
    // eject if on the goodbye page
    if (window.location.pathname === ROUTE_NAMES.GOODBYE) return

    // match an eos id, will match incoming url from card
    // :alpha?uid=:uid
    const match = matchPath<{ id: string; path: string | undefined }>(location.pathname, {
      path: '/:id/:path?',
      strict: false,
      exact: false,
    })

    const doFetch = (id: string | string[], idType: 'uid' | 'alpha' = 'uid') => {
      let nextId = id
      if (Array.isArray(id)) nextId = id[0]
      return fetch(`https://usersvc-eos-denver.meowwolf.com/api/${idType}/${nextId}`)
    }

    const fetchUser = async (id: string | string[], idType: 'uid' | 'alpha' = 'uid'): Promise<void> => {
      // don't refetch user
      if (fakeState.current) return
      setState({
        isLoading: true,
        hasErrors: false,
        gameState: null,
      })

      try {
        const res = await doFetch(id, idType)
        const json = await res.json()

        if (json._id) {
          const nextFakeState = { user: parseUserIntoGameState(json), projectCode: 'atm-on-web' }
          fakeState.current = nextFakeState
          setState({
            isLoading: false,
            hasErrors: false,
            gameState: nextFakeState,
          })
          return
        } else {
          handleError()
          return
        }
      } catch (_error) {
        handleError()
        return
      }
    }

    // todo: need to handle page reload without search param
    const params = queryString.parse(location.search)

    // Auth0 login error
    // loading splash screen and show error
    if (params.error && params.error_description) {
      setState({
        isLoading: false,
        hasErrors: false,
        gameState: null,
      })
      return
    }

    // get alpha
    // if no path, but an id it could be coming from the qr code url
    const matchedAlpha = match && !match.params.path && match.params.id.length <= ALPHA_MAX_LENGTH && match.params.id
    const alpha = params.alpha || matchedAlpha

    // if alpha, fetch user and return
    if (alpha) {
      fetchUser(alpha, 'alpha')
      return
    }

    // get either uid, or eos id (_id, :id)
    // NOTE: using the matchPath result is a little jenky
    const matchedId = match && !!match.params.path && match.params.id
    const uid = params.uid || params._id || matchedId
    // if uid, fetch user and return
    if (uid) {
      fetchUser(uid, 'uid')
      return
    }

    setState({
      isLoading: false,
      hasErrors: true,
      gameState: null,
    })
  }, [location.search, location.pathname, fakeState])

  return <GameStateContext.Provider value={state}>{children}</GameStateContext.Provider>
}

export default FakeAssGamestate
