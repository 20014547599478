/* eslint-disable no-console */
import { useAuth0 } from '@auth0/auth0-react'
import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core'
import React, { FunctionComponent, useState } from 'react'
import { useCookies } from 'react-cookie'
import { COOKIE_NAMES } from '../../constants'

const useStyles = makeStyles({
  avatar: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    overflow: 'hidden',
  },
  menuList: {
    padding: 0,
  },
  menuItem: {
    fontSize: '1rem',
  },
})

const AuthNav: FunctionComponent = () => {
  const classes = useStyles()
  const { logout, user, isLoading } = useAuth0()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [, , removeCookie] = useCookies()

  if (isLoading) {
    return <></>
  }

  const handleLogout = () => {
    removeCookie(COOKIE_NAMES.login_prompt)
    logout({ returnTo: `${location.origin}/goodbye` })
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {user && (
        <div>
          <Button aria-controls="user-menu" aria-haspopup="true" onClick={handleClick}>
            <div className={classes.avatar}>
              <img src={user.picture} alt="User Menu" />
            </div>
          </Button>
          <Menu
            id="user-menu"
            open={Boolean(anchorEl)}
            onClose={handleClose}
            keepMounted
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            elevation={0}
            PaperProps={{
              style: {
                borderRadius: 0,
                border: '1px solid #efefef',
                margin: 0,
                padding: 0,
              },
            }}
            MenuListProps={{
              dense: true,
              className: classes.menuList,
            }}
          >
            <MenuItem disabled divider dense>
              Welcome {user.name || 'Traveler'}
            </MenuItem>
            <MenuItem dense onClick={handleLogout}>
              Logout
            </MenuItem>
            <MenuItem dense onClick={() => setAnchorEl(null)}>
              Close
            </MenuItem>
          </Menu>
        </div>
      )}
    </>
  )
}

export default AuthNav
