import React, { useEffect, useState } from 'react'
import { Route, RouteProps, Switch, useHistory } from 'react-router-dom'
import { GameStateContext } from './utils/FakeAssGamestate'

import Dashboard from './components/dashboard/Dashboard'
import MemoryInventory from './components/memory-inventory/MemoryInventory'
import RecallBoards from './components/recall-boards/RecallBoards'
import Newsfeed from './components/newsfeed/Newsfeed'
import Goodbye from './components/goodbye/Goodbye'
import SplashScreen from './components/splash-screen/SplashScreen'
import Memos from './components/memos/Memos'
import { getAlphaFromPathname, routeToPath, ROUTE_NAMES } from './utils/route-utils'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from './components/common/Loading'
import { getUserEosID } from './utils/auth-utils'

/**
 * Wraps React Router's <Route> component to conditionally render a route only if the user is logged-in.
 */
const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { gameState } = React.useContext(GameStateContext)

  // Replace this route with the splash screen if the user is not logged-in
  // should never be able to get here
  if (!gameState) {
    return <SplashScreen />
  }

  // Else if the user is logged in, render the given routes
  return <Route {...props} />
}

/**
 * Application route declarations.
 */
const Routes: React.FC = () => {
  const history = useHistory()
  const [ready, setReady] = useState(false)
  const { gameState, isLoading: gameStateIsLoading, hasErrors: gameStateHasError } = React.useContext(GameStateContext)
  const { isLoading: auth0IsLoading, user } = useAuth0()

  useEffect(() => {
    /**
     * Redirect to dashboard if
     * - gameState and
     *   - root
     *   - pathname contains potential alpha
     */
    if (
      gameState &&
      (history.location.pathname === ROUTE_NAMES.ROOT || getAlphaFromPathname(history.location.pathname))
    ) {
      history.push(routeToPath(ROUTE_NAMES.DASHBOARD, gameState.user.id))
    }

    if (gameState) {
      setReady(true)
    }
  }, [gameState])

  // logged in user has EOS id, redirect from ROOT
  useEffect(() => {
    if (!gameState && gameStateHasError && user && history.location.pathname === ROUTE_NAMES.ROOT) {
      const eosID = getUserEosID(user)
      if (eosID) {
        history.push(routeToPath(ROUTE_NAMES.DASHBOARD, eosID))
        setReady(true)
      }
    }
  }, [gameStateHasError, gameState, user, history])

  if (window.location.pathname === ROUTE_NAMES.GOODBYE) {
    return <Goodbye />
  }

  /**
   * Show loading if
   * - auth0 is loading
   * - auth0 is not loading and we have a user, but no gamestate
   * - auth0 is not loading, no user, gamestate loading
   */
  if (
    auth0IsLoading ||
    (!auth0IsLoading && user && !gameState) ||
    (!auth0IsLoading && !user && gameStateIsLoading && !gameStateHasError) ||
    (!gameState && gameStateIsLoading && !gameStateHasError) ||
    (gameState && !ready)
  ) {
    return <Loading />
  }

  return (
    <Switch>
      {/* SplashScreen is a screensaver that shows the ATM logo */}
      <Route path={ROUTE_NAMES.ROOT} exact>
        <SplashScreen />
      </Route>
      {/* Dashboard is a homepage menu with links to the other views */}
      <PrivateRoute path={ROUTE_NAMES.DASHBOARD} exact>
        <Dashboard />
      </PrivateRoute>
      {/* MemoryInventory is a nodal view of collected memories */}
      <PrivateRoute path={ROUTE_NAMES.INVENTORY} exact>
        <MemoryInventory />
      </PrivateRoute>
      {/* RecallBoards display quests in a lost-and-found-classifieds style */}
      <PrivateRoute path={ROUTE_NAMES.RECALL_BOARDS} exact>
        <RecallBoards />
      </PrivateRoute>
      {/* Newsfeed is the Convergence Exchange news blog */}
      <PrivateRoute path={ROUTE_NAMES.NEWSFEED} exact>
        <Newsfeed />
      </PrivateRoute>
      <PrivateRoute path={ROUTE_NAMES.MEMOS} exact>
        <Memos />
      </PrivateRoute>
    </Switch>
  )
}

export default Routes
