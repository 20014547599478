import React from 'react'
import { Badge, Card, CardActionArea, CardContent, CardProps, Typography } from '@material-ui/core'
import { VerticalGrid, GridItem } from '@meowwolf/mw-denver-react'
import { Link, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

import sfx from '../../utils/sfx-utils'
import { RouteName, routeToPath } from '../../utils/route-utils'
import { segment } from '../../utils/analytics'

interface Props extends CardProps {
  img: string
  title: string
  routeName: RouteName
  unreadCount?: number
}

const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: 'none',
  },
  card: {
    height: '100%',
    boxShadow: '0px 0px 15px 2px #00000026',
  },
  title: {
    fontSize: '14px',
    fontWeight: 800,
    lineHeight: '12px',
    letterSpacing: 1,
  },
  img: {
    width: 80,
    height: 80,
    marginTop: '1rem',
  },
  badge: {
    position: 'absolute',
    float: 'right',
    right: -40,
    '& .MuiBadge-badge': {
      border: `2px solid ${theme.palette.background.default}`,
      fontSize: '2.25rem',
      fontWeight: 700,
      height: 70,
      minWidth: 70,
      borderRadius: 70,
    },
  },
}))

/**
 * One of three card-style links on the Dashboard homepage.
 */
const DashboardCard: React.FC<Props> = ({ img, title, routeName, unreadCount }) => {
  const classes = useStyles()
  const { id } = useParams<{ id: string }>()

  // Force each word of the title to render on a newline by splitting the text on whitespace
  const titleWords = title
    .trim()
    .split(' ')
    .map((word, idx) => (
      <Typography
        key={idx}
        gutterBottom
        variant="h4"
        component="h2"
        className={classes.title}
        align="center"
        color="primary"
      >
        {word}
      </Typography>
    ))

  const handleClick = () => {
    sfx.playTapSound()
    segment
      .dashBoardClick
      // title, routeToPath(routeName, id)
      ()
  }
  return (
    <Link to={routeToPath(routeName, id)} className={classes.root} onClick={() => handleClick()}>
      <Card className={classes.card}>
        <CardActionArea style={{ height: 'inherit' }}>
          <Badge badgeContent={unreadCount || 0} className={classes.badge} color="secondary" />
          <VerticalGrid container alignItems="center">
            {/* Image */}
            <GridItem className={classes.img}>
              <img src={img} title={title} alt={title} />
            </GridItem>
            {/* Title */}
            <GridItem>
              <CardContent>{titleWords}</CardContent>
            </GridItem>
          </VerticalGrid>
        </CardActionArea>
      </Card>
    </Link>
  )
}
export default DashboardCard
