import React, { useState, useEffect, ReactNode, ReactElement, createContext } from 'react'

import { ApiData } from './apiTypes'
import { getMems, getMemos, getNewsPosts, getMemories } from './apiCalls'

const emptyData: ApiData = {
  mems: [],
  memos: [],
  newsPosts: [],
  memories: [],
}

export const ApiContext = createContext(emptyData)

interface Props {
  children: ReactNode
}

const ApiProvider = ({ children }: Props): ReactElement => {
  const [apiData, setApiData] = useState<ApiData>(emptyData)

  const getData = async (): Promise<void> => {
    const [mems, memos, newsPosts, memories] = await Promise.all([getMems(), getMemos(), getNewsPosts(), getMemories()])

    const apiData = {
      mems,
      memos,
      newsPosts,
      memories,
    }
    setApiData(apiData)
  }

  useEffect(() => {
    getData()
  }, [])

  return <ApiContext.Provider value={apiData}>{children}</ApiContext.Provider>
}

export default ApiProvider
