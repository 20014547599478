import React from 'react'
import { ContainerWithNavBar } from '@meowwolf/mw-denver-react'
import { makeStyles, Typography, Theme } from '@material-ui/core'
import { InventoryItem } from '@meowwolf/react-platform-connection'
import { GameStateContext } from '../../utils/FakeAssGamestate'

import { ApiContext } from '../../apiStore'
import NewsfeedGrid from './Newsfeed-Grid'
import NavBar from '../common/NavBar'
import MemoWidget from '../common/MemoWidget'
import bgImgSrc from './assets/newsfeed-bg.png'
import { Strapi } from '@meowwolf/react-platform-connection/dist/convergenceTypings/strapiTypings'

export const VIEWED_ARTICLE_IDS_KEY = 'viewedArticleIdsArray' // Key of newspost IDs viewed in user state obj

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflowY: 'scroll',
  },
  title: {
    marginTop: 22,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: 22,
  },
  title1: {
    fontStyle: 'italic',
    color: theme.palette.secondary.main,
  },
  title2: {
    fontWeight: 800,
    color: theme.palette.primary.main,
  },
  titleSpacer: {},
}))

/**
 * The top-level Convergence Exchange Newsfeed blog page.
 */
const Newsfeed: React.FC = () => {
  const classes = useStyles()
  const [userArticles, setUserArticles] = React.useState<Article[]>([])
  const { gameState } = React.useContext(GameStateContext)
  const { newsPosts } = React.useContext(ApiContext)

  // Fetch articles
  React.useEffect(() => {
    const userArticleIds = getUserArticleIds(gameState?.user.inventory)
    const _userArticles = getUserArticles(newsPosts, userArticleIds)
    setUserArticles(_userArticles)
  }, [newsPosts, gameState])

  return (
    <>
      <MemoWidget />
      <ContainerWithNavBar
        bgImgSrc={bgImgSrc}
        className={classes.root}
        navbar={() => <NavBar title="Convergence Newsfeed" />}
      >
        <Typography variant="h1" className={classes.title}>
          <span className={classes.title1}>Convergence</span>
          <div className={classes.titleSpacer} />
          <span className={classes.title2}>Newsfeed</span>
        </Typography>
        <NewsfeedGrid articles={userArticles} />
      </ContainerWithNavBar>
    </>
  )
}

export default Newsfeed

const getUserArticles = (newsPosts: Strapi.NewsPost[], userArticleIds: Map<number, string>): Article[] => {
  return newsPosts
    .filter((newsPost: Strapi.NewsPost) => userArticleIds.has(newsPost.id))
    .map((newsPost: Strapi.NewsPost) => ({
      id: newsPost.id,
      title: newsPost.Title,
      body: newsPost.Body,
      characterName: newsPost.character.Name,
      publishedAt: userArticleIds.get(newsPost.id) as string,
      bannerImgSrc: newsPost.HeadlineImage?.OffsiteImage.url,
      iconImgSrc: newsPost.news_source.Logo.OffsiteImage?.url,
      video: newsPost.VideoAsset
        ? {
            src: newsPost.VideoAsset.OnsiteVideoURL,
            englishCaptionsURL: newsPost.VideoAsset.englishCaptionsURL,
            spanishCaptionsURL: newsPost.VideoAsset.spanishCaptionsURL,
          }
        : undefined,
    }))
    .sort((a: Article, b: Article) => {
      if (a.publishedAt === b.publishedAt) return 0

      const aDate = new Date(a.publishedAt)
      const bDate = new Date(b.publishedAt)

      return aDate > bDate ? -1 : 1
    })
}

/**
 * Return a set of Article IDs for all the articles in a user's inventory.
 */
function getUserArticleIds(userInventory?: readonly InventoryItem[]): Map<number, string> {
  const userArticles = new Map()
  if (!userInventory) {
    return userArticles
  }

  // Filter the user's inventory items for article IDs
  userInventory
    .filter((item) => item.configJson?.model.name === 'news-posts')
    .forEach((item) => {
      userArticles.set(item.configJson?.model.id, item.updatedAt)
    })

  return userArticles
}

export interface Article {
  id: number
  title: string
  body: string
  characterName: string
  publishedAt: string
  bannerImgSrc?: string
  iconImgSrc?: string
  video?: {
    src: string
    englishCaptionsURL: string
    spanishCaptionsURL: string
  }
}
