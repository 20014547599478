import React from 'react'
import { get } from 'lodash'
import { Typography, makeStyles, Grid } from '@material-ui/core'

import { VerticallyCentered, ContainerWithNavBar, GridItem } from '@meowwolf/mw-denver-react'
import { GameStateContext } from '../../utils/FakeAssGamestate'

import DashboardCard from './Dashboard-Card'
import NavBar from '../common/NavBar'
import MemoWidget from '../common/MemoWidget'

import memoryInventoryIcon from './assets/memory-inventory-icon.png'
import recallBoardsIcon from './assets/recall-boards-icon.png'
import convergenceIcon from './assets/convergence-icon.png'
import backgroundImgSrc from '../../assets/oss-crystals-bg.png'
import { ApiContext } from '../../apiStore'
import { MEMORY_IDS_VIEWED_KEY } from '../memory-inventory/MemoryInventory'
import { getCompleteMemoryIds } from '../../utils/memory-utils'
import { VIEWED_ARTICLE_IDS_KEY } from '../newsfeed/Newsfeed'
import { getUserArticleIds } from '../../utils/newsfeed-utils'
import Footer from '../common/Footer'
import { ROUTE_NAMES } from '../../utils/route-utils'

const useStyles = makeStyles({
  cards: {},
  title: {
    height: 80,
    padding: 0,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '1px',
    marginTop: '1rem',
  },
})

const MainMenu: React.FC = () => {
  const classes = useStyles()
  const { gameState } = React.useContext(GameStateContext)
  const [numUnreadMemories, setNumUnreadMemories] = React.useState(0)
  const [numUnreadArticles, setNumUnreadArticles] = React.useState(0)
  const { memories } = React.useContext(ApiContext)

  const memoryIdsViewed: readonly string[] = get(gameState?.user.state, MEMORY_IDS_VIEWED_KEY, [])
  const viewedArticleIds: readonly number[] = get(gameState?.user.state, VIEWED_ARTICLE_IDS_KEY, [])

  // Increment numUnreadMemories as user state updates
  React.useEffect(() => {
    if (!gameState) {
      console.error(`Failed to update ${MEMORY_IDS_VIEWED_KEY} in user state: gameState is undefined`)
      return
    }
    if (!memories.length) {
      console.error(`Failed to update ${MEMORY_IDS_VIEWED_KEY} in user state: /memories response from CMS is empty`)
      return
    }
    const completeMemoryIds = getCompleteMemoryIds({ gameState, memories })
    const _numUnreadMemories = completeMemoryIds.size - memoryIdsViewed.length
    setNumUnreadMemories(_numUnreadMemories)
  }, [gameState?.user.inventory.length, memoryIdsViewed.length, memories.length])

  // Increment numUnreadArticles as user state updates
  React.useEffect(() => {
    if (!gameState) {
      console.error(`Failed to update ${VIEWED_ARTICLE_IDS_KEY} in user state: gameState is undefined`)
      return
    }
    const userArticleIds = getUserArticleIds(gameState?.user.inventory)
    const _numUnreadArticles = userArticleIds.size - viewedArticleIds.length
    setNumUnreadArticles(_numUnreadArticles)
  }, [gameState?.user.inventory.length, viewedArticleIds.length])

  return (
    <>
      <MemoWidget />
      <ContainerWithNavBar
        bgImgSrc={backgroundImgSrc}
        navbar={() => <NavBar title="Dashboard" hideMainMenuLink />}
        maxWidth={false}
      >
        <Grid container>
          {/* Title */}
          <GridItem xs={12}>
            <VerticallyCentered>
              <Typography variant="h1" component="h1" align="center" className={classes.title}>
                Welcome to the
                <br />
                Convergence Exchange!
              </Typography>
            </VerticallyCentered>
          </GridItem>
          <GridItem xs={12}>
            {/* Cards */}
            <Grid container spacing={2}>
              {/* Memory Inventory */}
              <GridItem xs={6}>
                <DashboardCard
                  img={memoryInventoryIcon}
                  title="Memory Inventory"
                  routeName={ROUTE_NAMES.INVENTORY}
                  unreadCount={numUnreadMemories}
                />
              </GridItem>
              {/* Recall Boards */}
              <GridItem xs={6}>
                <DashboardCard img={recallBoardsIcon} title="Recall Boards" routeName={ROUTE_NAMES.RECALL_BOARDS} />
              </GridItem>
              {/* Convergence Exchange */}
              <GridItem xs={6}>
                <DashboardCard
                  img={convergenceIcon}
                  title="Convergence Newsfeed"
                  routeName={ROUTE_NAMES.NEWSFEED}
                  unreadCount={numUnreadArticles}
                />
              </GridItem>
              <GridItem xs={6}>
                <DashboardCard img={convergenceIcon} title="MEMos" routeName={ROUTE_NAMES.MEMOS} />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
        <Footer />
      </ContainerWithNavBar>
    </>
  )
}

export default MainMenu
