import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import DenverNavBar from './DenverNavBar'
import { routeToPath, ROUTE_NAMES } from '../../utils/route-utils'
import { segment } from '../../utils/analytics'

interface Props {
  title: string
  hideMainMenuLink?: boolean
  onClickGoBack?: () => void
  goBackText?: string
}

const NavBar: React.FC<Props> = ({ title, hideMainMenuLink, onClickGoBack, goBackText }) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  let onClickMainMenu: undefined | (() => void) = () => {
    segment.homeClick()
    history.push(routeToPath(ROUTE_NAMES.DASHBOARD, id))
  }

  if (hideMainMenuLink) {
    onClickMainMenu = undefined
  }

  const _onClickGoBack = () => {
    onClickGoBack && onClickGoBack()
  }

  return (
    <DenverNavBar
      title={title}
      goBackText={goBackText}
      onClickMainMenu={onClickMainMenu}
      onClickGoBack={_onClickGoBack}
    />
  )
}

export default NavBar
