import React from 'react'
// import moment from 'moment'
import { BackgroundImage, GridItem, HorizontalGrid, VerticalGrid, VerticallyCentered } from '@meowwolf/mw-denver-react'
import { GridProps, Typography, makeStyles } from '@material-ui/core'

import { Article } from './Newsfeed'
import newsfeedLogo from './assets/newsfeed-logo.png'
import newsfeedItemBg from './assets/newsfeed-item-bg.png'

// const ITEM_WIDTH_PX = 459

interface Props extends GridProps {
  article: Article
  isUnread: boolean
  onClickArticle: (id: number) => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
  },
  titleWrapper: {
    height: 75,
    width: '100%',
    margin: '16px 0 0 0',
    padding: 7,
  },
  title: {
    textAlign: 'center',
    fontSize: '1.125rem',
    lineHeight: '1.625rem',
    color: theme.palette.secondary.main,
  },
  footer: {
    height: 50,
    width: '100%',
    padding: '0 10px 10px 20px',
    textAlign: 'center',
    lineHeight: '1rem',
  },
  byline: {
    paddingLeft: 10,
    fontSize: '0.875rem',
    fontWeight: 300,
    lineHeight: '1rem',
  },
  author: {
    fontWeight: 800,
    color: theme.palette.primary.main,
  },
  bgImg: {
    width: '100%',
    height: 180,
    padding: 0,
    margin: 0,
  },
  icon: {
    height: 40,
    width: 50,
    margin: 0,
  },
  publishedAt: {
    width: 100,
    fontSize: '0.875rem',
    lineHeight: '1rem',
  },
  footerItem: {
    margin: 0,
    height: 40,
  },
  bordered: ({ isActive }: { isActive: boolean }) => ({
    border: `3px solid ${isActive ? theme.palette.secondary.main : theme.palette.background.paper}`,
  }),
}))

/**
 * A single article listing in the top-level Convergence Exchange Newsfeed page.
 * This is one square in the 3-column grid layout.
 */
const NewsfeedGridItem: React.FC<Props> = ({ article, onClickArticle, isUnread, ...gridProps }) => {
  const classes = useStyles({ isActive: isUnread })
  // const publishedAt = moment(article.publishedAt).format('MM/DD/YYYY')

  const onClick = () => {
    onClickArticle(article.id)
  }

  return (
    <GridItem {...gridProps} onClick={onClick} className={classes.root}>
      <div className={classes.bordered}>
        <BackgroundImage src={article.bannerImgSrc || newsfeedItemBg} className={classes.bgImg} />
        <VerticalGrid className={classes.container}>
          {/* Article Title */}
          <GridItem flexGrow>
            <VerticallyCentered className={classes.titleWrapper}>
              <Typography variant="h2" className={classes.title}>
                {article.title}
              </Typography>
            </VerticallyCentered>
          </GridItem>
          {/* Footer */}
          <GridItem className={classes.footer}>
            <HorizontalGrid spacing={0}>
              {/* News Logo */}
              <GridItem className={classes.footerItem}>
                <VerticallyCentered>
                  <BackgroundImage
                    src={article.iconImgSrc || newsfeedLogo}
                    className={classes.icon}
                    size="contain"
                    // position="top"
                  />
                </VerticallyCentered>
              </GridItem>
              {/* Byline */}
              <GridItem flexGrow className={classes.footerItem} justifyContent="flex-end" alignItems="center" container>
                <Typography variant="body2" className={classes.byline}>
                  Published by <span className={classes.author}>{article.characterName}</span>
                </Typography>
              </GridItem>
              {/* Date */}
            </HorizontalGrid>
          </GridItem>
        </VerticalGrid>
      </div>
    </GridItem>
  )
}
export default NewsfeedGridItem
