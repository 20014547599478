import React from 'react'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import { MediaPlayer, MediaPlayerProps } from '@meowwolf/mw-denver-react'

const VIDEO_CMS_URL = 'https://mw-cms.meowwolf.org/onsite-videos?Identifier=touch_no_boop'
let VIDEO_URL: string | undefined = undefined

interface Props {
  onComplete: () => void
}

/**
 * The "Touch No Boop" is a video that plays when a player touches the Boop Screen without booping.
 */
const TouchNoBoop: React.FC<Props> = ({ onComplete }) => {
  const [videoUrl, setVideoUrl] = React.useState<string | undefined>()

  const _onComplete = () => {
    onComplete()
  }

  const _onError = () => {
    console.error('Error in TouchNoBoop video playback')
    _onComplete()
  }

  const videoProps: MediaPlayerProps['video'] = {
    onAbort: _onComplete,
    onClose: _onComplete,
    onClick: _onComplete,
    onError: _onError,
  }

  // Fetch video asset URL
  React.useEffect(() => {
    fetchVideoAssetUrl()
      .then(setVideoUrl)
      .catch((err) => {
        console.error(err)
        onComplete()
      })
  }, [])

  if (!videoUrl) {
    return <></>
  }

  return <MediaPlayer src={videoUrl} onEnded={_onComplete} video={videoProps} />
}

export default TouchNoBoop

interface FetchResultItem {
  id: number
  Identifier: string
  URL: string
  created_at: string
  updated_at: string
  englishCaptionsURL: string | null
  spanishCaptionsURL: string | null
}

/**
 * Fetch, cache, and return the video asset URL from the CMS.
 */
const fetchVideoAssetUrl = async (): Promise<string> => {
  if (typeof VIDEO_URL === 'string') {
    // Return cached video URL if available
    return VIDEO_URL
  }

  // Fetch asset URL from the CMS
  const response = await fetch(VIDEO_CMS_URL)
  const result: readonly FetchResultItem[] = await response.json()

  if (!isArray(result)) {
    throw new Error(`Unexpected fetch response: expected array, got ${typeof result}`)
  }
  if (!result.length) {
    throw new Error('Unexpected fetch response: empty array')
  }
  if (!isPlainObject(result[0])) {
    throw new Error(`Unexpected fetch response: expected object, got ${typeof result[0]}`)
  }
  if (typeof result[0].URL !== 'string') {
    throw new Error(`Unexpected fetch respone: expected URL string, got ${result[0]}`)
  }

  // Cache the video URL for next time
  const resultItem: FetchResultItem = result[0]
  VIDEO_URL = resultItem.URL
  return VIDEO_URL
}
