import segmentPlugin from '@analytics/segment'
import { Analytics } from 'analytics'
// import { Strapi } from '@meowwolf/react-platform-connection/dist/convergenceTypings/strapiTypings'
import { Post } from '../components/recall-boards/RecallBoards'
import { IS_PRODUCTION } from '../constants'
export const analytics = Analytics({
  app: 'Denver Web Atm',
  plugins: [
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
    }),
  ],
})

export const segment = {
  pageTracking: (): void => {
    if (!IS_PRODUCTION) return
    analytics.page()
  },
  userMemCodeSubmitted: (code: string): void => {
    if (!IS_PRODUCTION) return
    analytics.identify(code)
    // analytics.track('UserLogin', { code })
  },
  codeModalOpen: (): void => {
    if (!IS_PRODUCTION) return
    // analytics.track('UserInputClick')
  },
  codeModalClose: (): void => {
    if (!IS_PRODUCTION) return
    // analytics.track('UserInputClick')
  },
  dashBoardClick: (): // title: string,
  // path: string
  void => {
    if (!IS_PRODUCTION) return

    // analytics.track(`UserInputClick`, {
    //   title,
    //   path,
    // })
  },
  // todo: add content types? i.e memo vs recall
  memoModalOpen: (): // memo: Strapi.Memo
  void => {
    if (!IS_PRODUCTION) return
    // analytics.track('UserInputClick', {
    //   title: memo.Title
    // })
    // analytics.track('MediaConsumeStart', {
    //   title: memo.Title
    // })
  },
  memoModalClose: (): // memo: Strapi.Memo
  void => {
    if (!IS_PRODUCTION) return
    // analytics.track('UserInputClick', {
    //   title: memo.Title
    // })
    // analytics.track('MediaConsumeEnd', {
    //   title: memo.Title
    // })
  },
  recallModalOpen: (listing: Post | undefined): void => {
    if (!IS_PRODUCTION || !listing) return
    // analytics.track('UserInputClick', {
    //   title: memo.Title
    // })
    // analytics.track('MediaConsumeStart', {
    //   title: memo.Title
    // })
  },
  recallModalClose: (listing: Post | undefined): void => {
    if (!IS_PRODUCTION || !listing) return
    // const { title } = listing
    // analytics.track('UserInputClick', {
    //   title,
    // })
    // analytics.track('MediaConsumeEnd', {
    //   title,
    // })
  },
  memNodeOpen: (title: string | undefined): void => {
    if (!IS_PRODUCTION || !title) return
    // analytics.track('UserInputClick', {
    //   title,
    // })
    // analytics.track('MediaConsumeStart', {
    //   title,
    // })
  },
  memNodeClose: (title: string | undefined): void => {
    if (!IS_PRODUCTION || !title) return
    // analytics.track('UserInputClick', {
    //   title,
    // })
    // analytics.track('MediaConsumeEnd', {
    //   title,
    // })
  },
  newsArticleOpen: (articleId: number | undefined): void => {
    if (!IS_PRODUCTION || !articleId) return
    // Todo is title more helpful here as opposed to articleId?
    // analytics.track('UserInputClick', {
    //   articleId,
    // })
    // analytics.track('MediaConsumeStart', {
    //   articleId,
    // })
  },
  newsArticleClose: (articleId: number | undefined): void => {
    if (!IS_PRODUCTION || !articleId) return
    // analytics.track('UserInputClick', {
    //   articleId,
    // })
    // analytics.track('MediaConsumeEnd', {
    //   articleId,
    // })
  },
  homeClick: (): void => {
    // analytics.track('home click - back to dashboard')
  },
}
