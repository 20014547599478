import { Typography } from '@material-ui/core'
import {
  BackgroundImage,
  Container,
  GridItem,
  HorizontalGrid,
  VerticalGrid,
  VerticallyCentered,
} from '@meowwolf/mw-denver-react'
import React, { FunctionComponent } from 'react'
import { FadeIn } from 'react-animated-components'

import bgImgSrc from '../../assets/hello-goodbye/bg.png'
import logoSrc from '../../assets/hello-goodbye/logo.png'

const Loading: FunctionComponent = () => {
  return (
    <Container bgImgSrc={bgImgSrc}>
      <VerticallyCentered>
        <HorizontalGrid>
          <GridItem flexGrow>
            <VerticalGrid>
              <GridItem>
                <FadeIn durationMs={100} delayMs={250} style={{ display: 'block' }}>
                  <BackgroundImage src={logoSrc} height="400px" width="300px" />
                </FadeIn>
              </GridItem>
              <GridItem>
                <FadeIn durationMs={100} delayMs={250}>
                  <Typography variant="h1" align="center" color="secondary">
                    Loading
                  </Typography>
                </FadeIn>
              </GridItem>
            </VerticalGrid>
          </GridItem>
        </HorizontalGrid>
      </VerticallyCentered>
    </Container>
  )
}

export default Loading
