import React from 'react'
import { Markdown } from '@meowwolf/mw-denver-react'
import { makeStyles, Typography } from '@material-ui/core'

interface Props {
  title?: string
  description?: string
  color?: string
  recallCode?: string
}

const useStyles = makeStyles((theme) => ({
  root: {},
  title: ({ color = theme.palette.text.primary }: { color?: string }) => ({
    color,
    fontSize: '1rem',
    lineHeight: '1rem',
    marginTop: '1rem',
  }),
  recallCode: ({ color = theme.palette.text.primary }: { color?: string }) => ({
    color,
    marginBottom: 16,
  }),
  scrollContainer: {
    maxHeight: '30vh',
    overflowY: 'auto',
    padding: '0 0 0.5rem',
  },
  body: {
    margin: '1.5rem',
    textAlign: 'center',
  },
}))

/**
 * Sidebar title and description for a memory inventory node.
 */
const MemoryInventorySideBarText: React.FC<Props> = ({ title, description, color, recallCode }) => {
  const classes = useStyles({ color: color?.trim() })
  return (
    <div className={classes.root}>
      {title && (
        <Typography variant="h1" align="center" className={classes.title}>
          {title}
        </Typography>
      )}
      {recallCode && (
        <Typography variant="h1" align="center" className={classes.recallCode}>
          #{recallCode}
        </Typography>
      )}
      {description && (
        <div className={classes.scrollContainer}>
          <div className={classes.body}>
            <Markdown>{description}</Markdown>
          </div>
        </div>
      )}
    </div>
  )
}

export default MemoryInventorySideBarText
