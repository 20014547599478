import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider, kioskTheme, ViewportBox } from '@meowwolf/mw-denver-react'
import Routes from './Routes'
import ApiProvider from './apiStore'
import FakeAssGamestate from './utils/FakeAssGamestate'
import { Auth0Provider } from '@auth0/auth0-react'

const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string
const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string
import { CookiesProvider } from 'react-cookie'
import LayoutProvider from './utils/LayoutProvider'
import { segment } from './utils/analytics'

const App: React.FC = () => {
  useEffect(() => {
    segment.pageTracking()
  }, [])

  return (
    <Auth0Provider domain={AUTH0_DOMAIN} clientId={AUTH0_CLIENT_ID} redirectUri={window.location.origin}>
      <CookiesProvider>
        <Router>
          <FakeAssGamestate>
            <ApiProvider>
              <ThemeProvider theme={kioskTheme}>
                <ViewportBox>
                  <LayoutProvider>
                    <Routes />
                  </LayoutProvider>
                </ViewportBox>
              </ThemeProvider>
            </ApiProvider>
          </FakeAssGamestate>
        </Router>
      </CookiesProvider>
    </Auth0Provider>
  )
}

export default App
