import mems from './mems.json'
import memos from './mem-os.json'
import memories from './memories.json'
import news from './news.json'

import { Strapi } from '@meowwolf/react-platform-connection/dist/convergenceTypings/strapiTypings'

export const getMems = async (): Promise<Strapi.Mem[]> => {
  return Promise.resolve(mems as unknown as Strapi.Mem[])
}

export const getMemos = async (): Promise<Strapi.Memo[]> => {
  return Promise.resolve(memos as unknown as Strapi.Memo[])
}

export const getNewsPosts = async (): Promise<Strapi.NewsPost[]> => {
  return Promise.resolve(news as unknown as Strapi.NewsPost[])
}

export const getMemories = async (): Promise<Strapi.Memory[]> => {
  return Promise.resolve(memories as unknown as Strapi.Memory[])
}
