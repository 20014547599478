import React from 'react'
import get from 'lodash/get'
import { useLocation } from 'react-router-dom'

import { Memo } from '@meowwolf/mw-denver-react'
import { withStyles } from '@material-ui/core'
import { InventoryItem } from '@meowwolf/react-platform-connection'
import { GameStateContext } from '../../utils/FakeAssGamestate'

import { Strapi } from '@meowwolf/react-platform-connection/convergenceTypings/strapiTypings'
import { ApiContext } from '../../apiStore'

const MEMO_IDS_READ_KEY = 'memoIdsReadArray'

/**
 * Wrap the MemoWidget from @meowwolf/mw-denver-react with logic to fetch memo data from the CMS.
 */

// const useStyles = makeStyles({
//   root: {
//     '& avatar': {
//       display: 'none',
//     },
//   },
// })

const MemoWidget: React.FC = () => {
  // const classes = useStyles()
  // const [isOpen, setIsOpen] = React.useState(false)
  // const [activeMemo, setActiveMemo] = React.useState<Memo>()
  const [userMemos, setUserMemos] = React.useState<Memo[]>([])
  const [, setAlertMemo] = React.useState<Memo | undefined>()
  const [numUnread, setNumUnread] = React.useState(0)
  const { gameState } = React.useContext(GameStateContext)
  // const platformInterface = React.useContext(PlatformInterfaceContext)
  const { memos } = React.useContext(ApiContext)
  const params = new URLSearchParams(useLocation().search)
  // const history = useHistory()
  const memoIdsRead = new Set(get(gameState?.user.state, MEMO_IDS_READ_KEY, []))

  // parse through memos from API to find user's memos
  React.useEffect(() => {
    const userMemoIds = getUserMemoIds(gameState?.user.inventory)
    const allMemos = parseMemos(memos, userMemoIds)
    const newUserMemos = allMemos
      .map((memo) => {
        memo.updatedAt = userMemoIds.get(memo.id) as string
        return memo
      })
      .sort((a: Memo, b: Memo) => {
        if (a.updatedAt === b.updatedAt) return 0

        const aDate = new Date(a.updatedAt)
        const bDate = new Date(b.updatedAt)

        return aDate > bDate ? -1 : 1
      })
    setUserMemos(newUserMemos)
  }, [gameState])

  // check url query params to see if user should get a new memo alert
  React.useEffect(() => {
    if (params.has('memoId') && userMemos.length) {
      const id = params.get('memoId') as string
      const memo = userMemos.find((memo) => memo.id === +id)
      if (memo) setAlertMemo(memo)
      else {
        console.error(`memo Id ${id} not in user inventory`)
      }
    }
  }, [params, userMemos])

  // Update numUnread as memos are read
  React.useEffect(() => {
    if (userMemos.length && gameState?.user.state) {
      const _numUnread = userMemos.length - memoIdsRead.size
      if (_numUnread !== numUnread) {
        setNumUnread(_numUnread)
      }
    }
  }, [userMemos.length, memoIdsRead.size])

  // removes alert from url if viewed
  // const clearAlert = () => {
  //   setAlertMemo(undefined)
  //   history.push(history.location.pathname)
  // }

  // Update memoIdsReadArray in user state as memos are activated
  // function onActiveMemo(memoId: number) {
  //   const foundMemo = memos.find((m) => m.id === memoId)
  //   if (foundMemo)
  //     setActiveMemo({
  //       ...foundMemo,
  //       body: foundMemo?.Body || 'conspicuously absent memo body',
  //       characterName: foundMemo?.character.Name || 'unknown sender',
  //       title: foundMemo?.Title || 'a mysterious message',
  //       updatedAt: 'some glad morning, when this life is over',
  //     })
  // }

  return <></>
}

export default withStyles({
  root: {
    background: 'red',
  },
})(MemoWidget)

const parseMemos = (apiMemos: Strapi.Memo[], userMemoIds: Map<number, string>): Memo[] => {
  // Parse each response item to match the Memo interface (or undefined on failure)
  return apiMemos
    .filter((memo) => userMemoIds.has(memo.id))
    .map((obj) => {
      // Get the props we need from the reponse item
      const id = obj.id
      const title = obj.Title
      const body = obj.Body
      const characterName = obj.character?.Name
      const characterAvatarSrc = obj.character?.Avatar?.OnsiteImageLink
      const videoUrl = obj.VideoMemoURL?.OnsiteVideoURL
      const englishCaptionsURL = obj.VideoMemoURL?.englishCaptionsURL
      const spanishCaptionsURL = obj.VideoMemoURL?.spanishCaptionsURL
      return {
        id,
        title,
        body,
        characterName,
        characterAvatarSrc,
        videoUrl,
        englishCaptionsURL,
        spanishCaptionsURL,
        updatedAt: userMemoIds.get(obj.id) as string,
        eosCode: obj.eosCode,
      }
    })
}

/**
 * Return a map of Memo IDs to user's updated data for all the memos in a user's inventory.
 */
function getUserMemoIds(userInventory?: ReadonlyArray<InventoryItem>): Map<number, string> {
  const ids = new Map()
  if (!userInventory) {
    return ids
  }
  userInventory
    .filter((item) => item?.configJson?.model?.name === 'mem-os')
    .forEach((item) => {
      ids.set(item?.configJson?.model.id, item.updatedAt)
    })
  return ids
}
