import React, { MouseEvent, useEffect, useState } from 'react'
import { BackgroundImage, GridItem } from '@meowwolf/mw-denver-react'
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  Modal,
  Theme,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Cancel'
import TouchNoBoop from './SplashScreen-TouchNoBoop'
import splashScreenImgSrc from './assets/splash-screen.png'
import { useAuth0 } from '@auth0/auth0-react'
import queryString from 'query-string'
import { startCase } from 'lodash'
import { useHistory } from 'react-router'
import { ALPHA_MAX_LENGTH, closeButtonStyles } from '../../constants'
import { ROUTE_NAMES } from '../../utils/route-utils'
import { segment } from '../../utils/analytics'

const useStyles = makeStyles((theme: Theme) => ({
  headerButtonsContainer: {
    padding: 30,
  },
  headerButtons: {},
  fakeBoopBtnStyle: {
    float: 'right',
    zIndex: theme.zIndex.tooltip + 1,
  },
  paper: {
    position: 'absolute',
    width: 400,
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    padding: '1.5rem',
    outline: 0,
  },
  paperInner: {
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    padding: theme.spacing(2, 4, 3),
    position: 'relative',
  },
  closeButton: {
    ...closeButtonStyles,
  },
}))

/**
 * A screensaver view showing the Convergence Exchange ATM logo.
 * Redirects to the Boop Screen on any user interaction.
 */
const SplashScreen: React.FC = () => {
  // const gameState = React.useContext(GameStateContext)
  const [playVideo, setPlayVideo] = useState(false)
  // const [redirecting, setRedirecting] = useState(false)
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)
  const [error, setError] = useState<{ title: string; description: string } | undefined>()
  const inputRef = React.useRef<HTMLInputElement | undefined>()
  const history = useHistory()

  useEffect(() => {
    const params = queryString.parse(location.search)
    if (params.error && params.error_description) {
      const title = startCase(params.error as string)
      const description = params.error_description as string
      setError({
        title,
        description,
      })
      history.replace(ROUTE_NAMES.ROOT)
    }
    setModalOpen(true)
  }, [])

  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading: auth0IsLoading,
    // user,
  } = useAuth0()

  // Simulate a "boop" event and log in Test User
  const onFakeBoop = (e: MouseEvent) => {
    e.stopPropagation()
    const user = process.env.REACT_APP_TEST_USER || 'E0040150F71A0272'
    if (user) {
      window.location.href = `${window.location.origin}?uid=${user}`
    }
  }

  const handleCodeModalClose = () => {
    segment.codeModalClose()
    setModalOpen(false)
  }
  const handleErrorModalClose = () => {
    setError(undefined)
  }

  // error code = submitted but no game state
  const handleSubmitCode = () => {
    if (inputRef.current) {
      const val = inputRef.current.value
      if (val && val.length > 0) {
        const idType = val.length > ALPHA_MAX_LENGTH ? 'uid' : 'alpha'
        window.location.href = `${ROUTE_NAMES.ROOT}?${idType}=${val.toUpperCase()}`
        segment.userMemCodeSubmitted(val)
      }
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSubmitCode()
    }
  }

  return (
    <>
      {playVideo && <TouchNoBoop onComplete={() => setPlayVideo(false)} />}
      <BackgroundImage src={splashScreenImgSrc}>
        <Grid container justifyContent="space-between" className={classes.headerButtonsContainer}>
          <GridItem>
            {process.env.NODE_ENV === 'development' && (
              <Button variant="outlined" className={classes.fakeBoopBtnStyle} onClick={onFakeBoop}>
                Fake Boop
              </Button>
            )}
          </GridItem>
          <GridItem xs={6}>
            <Grid container spacing={1} justifyContent="flex-end">
              <GridItem>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setModalOpen(true)
                    segment.codeModalOpen()
                  }}
                  className={classes.headerButtons}
                >
                  Enter code
                </Button>
              </GridItem>
              <GridItem>
                {!auth0IsLoading && !isAuthenticated && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>
                      loginWithRedirect({
                        redirectUri: location.origin,
                      })
                    }
                  >
                    Login
                  </Button>
                )}
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
      </BackgroundImage>
      {/** Code modal */}
      <Modal
        open={modalOpen}
        onClose={handleCodeModalClose}
        aria-labelledby="enter-code-title"
        aria-describedby="code-helper-text"
      >
        <div className={classes.paper}>
          <div className={classes.paperInner}>
            <Button className={classes.closeButton} onClick={handleCodeModalClose}>
              <CloseIcon />
            </Button>
            <h2 id="enter-code-title">Enter your code</h2>
            <p>
              <FormControl focused required>
                <InputLabel htmlFor="code-input">Code</InputLabel>
                <Input
                  autoFocus
                  onKeyPress={handleKeyPress}
                  inputRef={inputRef}
                  id="code-input"
                  aria-describedby="code-helper-text"
                />
                <FormHelperText id="code-helper-text">See back of card</FormHelperText>
              </FormControl>
              <Button type="submit" onClick={handleSubmitCode}>
                Submit
              </Button>
            </p>
          </div>
        </div>
      </Modal>
      {/** Error modal */}
      <Modal
        open={!!error}
        onClose={handleErrorModalClose}
        aria-labelledby="error-title"
        aria-describedby="error-description"
      >
        <div className={classes.paper}>
          <div className={classes.paperInner}>
            <Button className={classes.closeButton} onClick={handleErrorModalClose}>
              <CloseIcon />
            </Button>
            {error && (
              <>
                <h2 id="error-title">{error.title}</h2>
                <p id="error-description">{error.description}</p>
              </>
            )}
            <Button type="submit" onClick={handleErrorModalClose}>
              Okay
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SplashScreen
