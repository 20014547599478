import React from 'react'
import { PlatformInterfaceContext } from '@meowwolf/react-platform-connection'
import { GameStateContext } from '../../utils/FakeAssGamestate'
import { MemoryGraph, Memories, Node, ForceGraph3DInstance } from '@meowwolf/mw-denver-react'
import { Mem } from '@meowwolf/mw-denver-react/dist/components/inventory/MemoryGraph'

import NavBar from '../common/NavBar'
import MemoryInventorySideBar from './MemoryInventory-SideBar'
import MemoWidget from '../common/MemoWidget'
import { get } from 'lodash'
import { segment } from '../../utils/analytics'

export const MEMORY_IDS_VIEWED_KEY = 'memoryIdsViewedArray' // Key of memory IDs viewed in user state obj
export const WORLD_NAMES = ['C Street', 'Earth', 'Eemia', 'Numina', 'Ossuary'] as const
const MEMORIES_API_URL = 'https://mw-cms.meowwolf.org/memories/'
const MEMS_API_URL = 'https://mw-cms.meowwolf.org/mems/'

// Names of fullscreen events in different browsers.
const FULLSCREEN_EVENTS = ['fullscreenchange', 'webkitfullscreenchange']

/**
 * Top level view for the Memory Inventory.
 */
const MemoryInventory: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const [activeNode, setActiveNode] = React.useState<Node>()
  const nodeRef = React.useRef<Node>()
  const [memories, setMemories] = React.useState<Memories>([])
  const [mems, setMems] = React.useState<Array<Mem>>([])
  const { gameState } = React.useContext(GameStateContext)
  const platformInterface = React.useContext(PlatformInterfaceContext)
  const ref = React.useRef<ForceGraph3DInstance>(null)

  const userMemIds: Array<number> = gameState?.user.inventory
    .filter((item) => item.configJson && item.configJson.model.name === 'mems')
    //@ts-expect-error - configJson is not typed in RPC yet
    .map((item) => item.configJson.model.id) as Array<number>

  // Get all the id of every mem that has a memory
  const memoryMemIds: Set<number> = new Set()
  memories.forEach((memory) => {
    memory.mems.forEach((mem) => {
      memoryMemIds.add(mem.id)
    })
  })

  // Get user mems without memories
  const noMemoryMems = React.useMemo(() => {
    const _noMemoryMems: Array<Mem> = []
    userMemIds.forEach((id) => {
      // if this mem has a memory do nothing
      if (memoryMemIds.has(id)) return

      const mem = mems.find((mem) => mem.id === id)

      if (!mem) return
      _noMemoryMems.push(mem)
    })
    return _noMemoryMems
  }, [userMemIds, memoryMemIds, mems])

  // Update memoryIdsViewedArray on user state when a memory node is activated
  React.useEffect(() => {
    if (activeNode?.meta?.type !== 'memory') {
      return // ignore mems
    }
    if (!gameState) {
      console.error(`Failed to update ${MEMORY_IDS_VIEWED_KEY} on user state: gameState is undefined`)
      return
    }
    if (!platformInterface) {
      console.error(`Failed to update ${MEMORY_IDS_VIEWED_KEY} on user state: platformInterface is undefined`)
      return
    }

    // Get the current list of memoryIdsViewed
    const memoryIdsViewed: Set<string> = new Set(get(gameState?.user.state, MEMORY_IDS_VIEWED_KEY, []))

    // Update memoryIdsViewed on user state if the current memory is new
    if (!memoryIdsViewed.has(activeNode.id)) {
      memoryIdsViewed.add(activeNode.id)
      platformInterface.updateUserState({
        ...gameState.user.state,
        [MEMORY_IDS_VIEWED_KEY]: Array.from(memoryIdsViewed),
      })
    }
  }, [activeNode])

  React.useEffect(() => {
    // handler for playing and pausing MemoryGraph rendering
    function handleFullScreenChange() {
      if (document.fullscreenElement) {
        ref.current?.pauseAnimation()
      } else {
        ref.current?.resumeAnimation()
      }
    }

    FULLSCREEN_EVENTS.forEach((event) => document.addEventListener(event, handleFullScreenChange, false))

    return () => {
      FULLSCREEN_EVENTS.forEach((event) => document.removeEventListener(event, handleFullScreenChange, false))
    }
  }, [])

  React.useEffect(() => {
    async function setMemData(): Promise<void> {
      const [memories, mems] = await Promise.all([fetchMemories(MEMORIES_API_URL), fetchMems(MEMS_API_URL)])

      /**
       * Complete the 'Rememberer' property in every memorie's mems.
       * We need this because CMS memory mems have an inclomplete 'Rememberer' property
       */
      const completeMemories = memories.map((memory) => {
        const completeMemData = memory.mems.map((worldMem) => {
          return {
            ...worldMem,
            Rememberer: mems.find((mem) => mem.id === worldMem.id)?.Rememberer,
          }
        })
        return {
          ...memory,
          mems: completeMemData,
        }
      })

      setMemories(completeMemories)
      setMems(mems)
    }

    try {
      setMemData()
    } catch (e) {
      console.error(`Error fetching memory data: ${e}`)
    }
  }, [])

  const onClose = () => {
    if (nodeRef && nodeRef.current) {
      segment.memNodeClose(nodeRef.current.name)
    }
    setOpen(false)
    setActiveNode(undefined)
  }

  const onNodeClick = (node: Node) => {
    segment.memNodeOpen(node.name)
    setOpen(true)
    setActiveNode(node)
  }

  const setActiveNodeId = (nodeId: string | undefined): void => {
    const node = ref.current?.graphData().nodes.find((n) => n.id === nodeId) as Node
    setActiveNode(node)
    nodeRef.current = node
  }

  // Calculate the values we need to navigate between nodes in order
  const nodes = ref.current?.graphData().nodes as Node[] | undefined
  const worldNodes = getWorldNodes(nodes)
  const activeWorld = WORLD_NAMES.find((worldName) => worldName === activeNode?.meta?.world)
  const activeWorldIdx = activeWorld ? worldNodes[activeWorld].findIndex((n) => n.id === activeNode?.id) : undefined

  return (
    <>
      <NavBar title="Memory Inventory" />
      <MemoWidget />
      <MemoryGraph
        onNodeClick={onNodeClick}
        onBackgroundClick={onClose}
        memories={memories}
        soloMems={noMemoryMems}
        userMemIds={userMemIds}
        setActiveNodeId={setActiveNodeId}
        activeNodeId={activeNode?.id}
        ref={ref}
      />
      <MemoryInventorySideBar
        activeNode={activeNode}
        isOpen={open}
        worldNodes={worldNodes}
        activeWorld={activeWorld}
        activeWorldIdx={activeWorldIdx}
        setActiveNodeId={setActiveNodeId}
        onClose={onClose}
      />
    </>
  )
}

async function fetchMemories(url: string): Promise<Memories> {
  try {
    const response = await fetch(url)
    const memories: Memories = await response.json()
    return memories
  } catch (e) {
    console.error(`JSON response from ${url} is invalid: ${e}`)
    return []
  }
}

async function fetchMems(url: string): Promise<Array<Mem>> {
  try {
    const response = await fetch(url)
    const mems: Array<Mem> = await response.json()
    return mems
  } catch (e) {
    console.error(`JSON response from ${url} is invalid: ${e}`)
    return []
  }
}

export type WorldName = typeof WORLD_NAMES[number]
export type WorldNodes = Record<WorldName, Node[]>

function getWorldNodes(nodes: readonly Node[] | undefined): WorldNodes {
  const worldNodes: WorldNodes = { 'C Street': [], Earth: [], Eemia: [], Numina: [], Ossuary: [] }

  nodes?.forEach((node) => {
    const maybeWorld = node.meta?.world
    const world = WORLD_NAMES.find((worldName) => worldName === maybeWorld)
    if (world) {
      worldNodes[world].push(node)
    }
  })

  return worldNodes
}

export default MemoryInventory
