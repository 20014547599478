import React from 'react'
import {
  BackgroundImage,
  GridItem,
  VerticallyCentered,
  VerticalGrid,
  HorizontalGrid,
  Container,
} from '@meowwolf/mw-denver-react'

import bgImgSrc from '../../assets/hello-goodbye/bg.png'
import logoSrc from '../../assets/hello-goodbye/logo.png'
import { FadeIn } from 'react-animated-components'
import { Typography } from '@material-ui/core'
import { useTimeoutFn } from 'react-use'
import { ROUTE_NAMES } from '../../utils/route-utils'

const DURATION_MS = 5000

/**
 * "Goodbye" splash screen shown temporarily after logout.
 */
const Goodbye: React.FC = () => {
  useTimeoutFn(() => {
    // NOTE: We use location.replace() instead of history.push() to intentionally force a page reload:
    // A logout action is automatically sent when the page reloads
    window.location.replace(ROUTE_NAMES.ROOT)
  }, DURATION_MS)

  return (
    <Container bgImgSrc={bgImgSrc}>
      <VerticallyCentered>
        <HorizontalGrid>
          <GridItem flexGrow>
            <VerticalGrid>
              <GridItem>
                <FadeIn durationMs={1000} delayMs={1000} style={{ display: 'block' }}>
                  <BackgroundImage src={logoSrc} height="400px" />
                </FadeIn>
              </GridItem>
              <GridItem>
                <FadeIn durationMs={1000} delayMs={2000}>
                  <Typography variant="h1" align="center" color="secondary">
                    Goodbye!
                  </Typography>
                  <Typography variant="h2" component="h3" align="center">
                    We‘ll never forget you!
                  </Typography>
                </FadeIn>
              </GridItem>
            </VerticalGrid>
          </GridItem>
        </HorizontalGrid>
      </VerticallyCentered>
    </Container>
  )
}

export default Goodbye
