import { ALPHA_MAX_LENGTH } from '../constants'

export const ROUTE_NAMES = {
  ROOT: '/',
  DASHBOARD: '/:id/dashboard',
  INVENTORY: '/:id/inventory',
  NEWSFEED: '/:id/newsfeed',
  RECALL_BOARDS: '/:id/recall-boards',
  GOODBYE: '/goodbye',
  MEMOS: '/:id/memos',
} as const

export type RouteName = typeof ROUTE_NAMES[keyof typeof ROUTE_NAMES]

export const routeToPath = (path: string, id: string): string => path.replace(':id', id)

export const getAlphaFromPathname = (path: string): string | false => {
  const potentialAlpha = path.split('/').filter((item) => !!item.length)[0]
  return potentialAlpha.length <= ALPHA_MAX_LENGTH && potentialAlpha !== ROUTE_NAMES.GOODBYE ? potentialAlpha : false
}
