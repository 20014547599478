import React, { CSSProperties } from 'react'
import { makeStyles } from '@material-ui/core'
import Lottie from 'react-lottie-player'
import { ContainerWithNavBar } from '@meowwolf/mw-denver-react'

import MemoWidget from '../common/MemoWidget'
import NavBar from '../common/NavBar'
import RecallBoardsGrid from './RecallBoards-Grid'
import animatedHeader from './assets/recall-boards-bg-animation.json'

const POSTS_URL = 'https://mw-cms.meowwolf.org/recall-board-listings'

const useStyles = makeStyles({
  root: {
    overflowY: 'scroll',
    height: '100vh',
  },
  header: {
    position: 'absolute',
    width: '100vw',
    top: 70,
    zIndex: -1,
  },
  footer: {
    width: '100%',
    height: 400,
  },
})

const ANIMATION_STYLES: CSSProperties = {
  position: 'relative',
}

/**
 * Recall Boards presents a list of quests in a 4-column blog layout.
 */
const RecallBoards: React.FC = () => {
  const [posts, setPosts] = React.useState<ReadonlyArray<Post>>([])
  const classes = useStyles()

  // Fetch posts
  React.useEffect(() => {
    fetchPosts().then(setPosts)
  }, [])

  return (
    <>
      <MemoWidget />
      <ContainerWithNavBar className={classes.root} navbar={() => <NavBar title="Recall Boards" />}>
        <div className={classes.header}>
          <Lottie animationData={animatedHeader} play loop={false} style={ANIMATION_STYLES} />
        </div>
        <RecallBoardsGrid posts={posts} />
      </ContainerWithNavBar>
    </>
  )
}

export default RecallBoards

export interface Post {
  id: number
  title: string
  body: string
  characterName: string
  iconImgSrc?: string
  characterAvatarSrc?: string
}

interface CmsImage {
  OffsiteImage: null | {
    url: string
  }
  OnsiteImageLink: string | null
}

type PostsApiResponse = ReadonlyArray<{
  id: number
  Title: string
  Description: string
  Icon: CmsImage
  Poster: {
    Name: string
    Avatar: CmsImage
  }
  Type: string
}>

/**
 * Retrieve a list of all recall board posts from the CMS.
 */
async function fetchPosts(): Promise<readonly Post[]> {
  try {
    const response = await fetch(POSTS_URL)
    const data: PostsApiResponse = await response.json()
    return data.map((obj) => ({
      id: obj.id,
      title: obj.Title,
      body: obj.Description,
      characterName: obj.Poster.Name,
      iconImgSrc: obj.Icon.OffsiteImage?.url,
      characterAvatarSrc: obj.Poster.Avatar.OffsiteImage?.url,
    }))
  } catch (err) {
    console.error(err)
    return []
  }
}
