import React from 'react'
import { get } from 'lodash'
import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { GridItem, HorizontalGrid } from '@meowwolf/mw-denver-react'
import { PlatformInterfaceContext } from '@meowwolf/react-platform-connection'
import { GameStateContext } from '../../utils/FakeAssGamestate'

import { Article, VIEWED_ARTICLE_IDS_KEY } from './Newsfeed'
import NewsfeedArticle from './Newsfeed-Article'
import NewsfeedGridItem from './Newsfeed-GridItem'
import { segment } from '../../utils/analytics'

interface Props {
  articles: Article[]
}

const useStyles = makeStyles({
  articlesStyles: {},
})

/**
 * The 3-column grid of articles in the Convergence Exchance Newsfeed.
 * Also contains the modal dialog that contains the article detail view.
 */
const NewsfeedGrid: React.FC<Props> = ({ articles }) => {
  const [activeArticleId, setActiveArticleId] = React.useState<number | undefined>(undefined)
  const { gameState } = React.useContext(GameStateContext)
  const platformInterface = React.useContext(PlatformInterfaceContext)
  const activeArticle = articles.find((article) => article.id === activeArticleId)
  const onClose = () => {
    segment.newsArticleClose(activeArticleId)
    setActiveArticleId(undefined)
  }
  const onClick = (id: number) => {
    setActiveArticleId(id)
    segment.newsArticleOpen(id)
  }
  const { articlesStyles } = useStyles()
  const articleIdsViewed: Set<number> = new Set(get(gameState?.user.state, VIEWED_ARTICLE_IDS_KEY, []))

  React.useEffect(() => {
    if (typeof activeArticleId !== 'number') {
      return // Skip if there's no active article
    }
    if (!gameState) {
      console.error(`Failed to update ${VIEWED_ARTICLE_IDS_KEY} on user state: gameState is undefined`)
      return
    }
    if (!platformInterface) {
      console.error(`Failed to update ${VIEWED_ARTICLE_IDS_KEY} on user state: platformInterface is undefined`)
      return
    }

    // Update viewedArticleIdsArray on user state if the active article is new
    if (!articleIdsViewed.has(activeArticleId)) {
      articleIdsViewed.add(activeArticleId)
      platformInterface.updateUserState({
        ...gameState.user.state,
        [VIEWED_ARTICLE_IDS_KEY]: Array.from(articleIdsViewed),
      })
    }
  }, [activeArticleId, articleIdsViewed.size])

  React.useEffect(() => {
    if (typeof activeArticleId !== 'number') {
      return // Skip if there's no active article
    }
    if (!gameState) {
      console.error(`Failed to update ${VIEWED_ARTICLE_IDS_KEY} on user state: gameState is undefined`)
      return
    }
    if (!platformInterface) {
      console.error(`Failed to update ${VIEWED_ARTICLE_IDS_KEY} on user state: platformInterface is undefined`)
      return
    }

    // Get the current list of viewedArticleIdsArray from user state
    const articleIdsViewed: Set<number> = new Set(get(gameState?.user.state, VIEWED_ARTICLE_IDS_KEY, []))

    // Update viewedArticleIdsArray on user state if the active article is new
    if (!articleIdsViewed.has(activeArticleId)) {
      articleIdsViewed.add(activeArticleId)
      platformInterface.updateUserState({
        ...gameState.user.state,
        [VIEWED_ARTICLE_IDS_KEY]: Array.from(articleIdsViewed),
      })
    }
  }, [activeArticleId])

  return (
    <>
      {/* Articles grid */}
      <GridItem container justifyContent="center" alignContent="center">
        <GridItem>
          <HorizontalGrid flexWrap="wrap" justifyContent="flex-start" className={articlesStyles} spacing={2}>
            {articles.map((article) => (
              <NewsfeedGridItem
                key={article.id}
                article={article}
                onClickArticle={onClick}
                isUnread={!articleIdsViewed.has(article.id)}
              />
            ))}
          </HorizontalGrid>
        </GridItem>
      </GridItem>
      {/* Article modal */}
      <Dialog fullScreen open={activeArticleId !== undefined} onClose={onClose}>
        {activeArticle && <NewsfeedArticle onClose={onClose} article={activeArticle} />}
      </Dialog>
    </>
  )
}

export default NewsfeedGrid
