function playBoopSound(_volume = 1): void {
  // console.log('no, I dont think I will')
}

function playTapSound(_volume = 1): void {
  // console.log('no tap sound on the web')
}

export default {
  playBoopSound,
  playTapSound,
}
