import { CSSProperties } from 'react'

/** Alpha id */
export const ALPHA_MAX_LENGTH = 8

export const BREAKPOINTS = {
  xxs: 330,
  xs: 480,
  sm: 576,
  md: 768,
  lg: 922,
  xl: 1200,
}

/** Cookies */
const COOKIE_NAME_ROOT = 'mw_mem_atm'

export const COOKIE_NAMES = {
  login_prompt: `${COOKIE_NAME_ROOT}_login_prompt`,
}

export const closeButtonStyles: CSSProperties = {
  position: 'absolute',
  top: 4,
  right: 0,
  color: '#FAC915',
}

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'
