import React, { FunctionComponent, useContext, useState } from 'react'
import { ContainerWithNavBar, Markdown } from '@meowwolf/mw-denver-react'
import { List, ListItem, Avatar, Grid, makeStyles, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Cancel'
import NavBar from '../common/NavBar'
import { GameStateContext } from '../../utils/FakeAssGamestate'
import { ApiContext } from '../../apiStore'
import { Strapi } from '@meowwolf/react-platform-connection/dist/convergenceTypings/strapiTypings'
import unknownUser from '../../assets/icons/unknown-user.svg'
import { segment } from '../../utils/analytics'

const useStyles = makeStyles({
  root: {},
  memoContent: {
    paddingTop: '36px',
  },
  coolListThingy: {
    borderBottom: '1px solid #605487',
  },
  list: {
    overflowY: 'auto',
    maxHeight: '90vh',
  },
  closeButton: {
    color: '#FAC915',
    padding: 0,
  },
  memoScrollContainer: {
    overflowY: 'auto',
    height: 'calc(100vh - (90px + 40px))',
  },
  contentContainer: {
    paddingRight: '1rem',
  },
})

const Memos: FunctionComponent = () => {
  const classes = useStyles()
  const { gameState } = useContext(GameStateContext)
  const { memos } = useContext(ApiContext)
  const [activeMemo, setActiveMemo] = useState<Strapi.Memo>()

  const memoStill =
    activeMemo && memos.find((m) => m.eosCode === `${activeMemo.eosCode}-memmemo`)?.mem_memo_still?.OffsiteImage?.url

  const handleMemoOpen = (memo: Strapi.Memo) => {
    setActiveMemo(memo)
    segment
      .memoModalOpen
      // memo
      ()
  }
  const handleMemoClose = () =>
    // memo: Strapi.Memo
    {
      segment
        .memoModalClose
        // memo
        ()
      setActiveMemo(undefined)
    }

  return (
    <ContainerWithNavBar navbar={() => <NavBar title="MEMos" />}>
      {activeMemo && (
        <Grid container>
          <Grid item>
            <Button
              className={classes.closeButton}
              onClick={
                () => handleMemoClose()
                // activeMemo
              }
            >
              <CloseIcon />
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.memoScrollContainer}>
            <Grid container alignItems="center" style={{ marginBottom: '2rem' }}>
              <Grid xs={4}>
                {activeMemo.character.Avatar.OffsiteImage && (
                  <Avatar
                    src={activeMemo.character.Avatar.OffsiteImage.url}
                    style={{ width: '85px', height: '85px' }}
                  />
                )}
              </Grid>
              <Grid xs={8}>
                <h2>{activeMemo.character.Name}</h2>
              </Grid>
            </Grid>

            {activeMemo.Body.trim().length > 0 && (
              <div className={classes.contentContainer}>
                <Markdown>{activeMemo.Body}</Markdown>
              </div>
            )}

            {/** No body and a vimeo id */}
            {activeMemo.Body.trim().length === 0 && activeMemo.VideoMemoURL?.VimeoID && (
              <>
                <iframe
                  src={`https://player.vimeo.com/video/${activeMemo.VideoMemoURL.VimeoID}`}
                  style={{ border: 0, width: '100%' }}
                />
              </>
            )}

            {memos.find((m) => m.eosCode === `${activeMemo.eosCode}-memmemo`) && (
              <>
                {memos.find((m) => m.eosCode === `${activeMemo.eosCode}-memmemo`)?.mem_memo_mems.length === 1 && (
                  <p>{activeMemo.character.Name} shared a mem</p>
                )}
                {memos.find((m) => m.eosCode === `${activeMemo.eosCode}-memmemo`)?.mem_memo_mems.length === 2 && (
                  <p>{activeMemo.character.Name} shared mems</p>
                )}
                {memos.find((m) => m.eosCode === `${activeMemo.eosCode}-memmemo`)?.mem_memo_mems.length === 3 && (
                  <p>{activeMemo.character.Name} shared a memory</p>
                )}
                {memoStill && <img alt="memo still" src={memoStill} />}
              </>
            )}
          </Grid>
        </Grid>
      )}

      {!activeMemo && (
        <List className={classes.list}>
          {gameState?.user.inventory
            .filter((i) => i.code.startsWith('memo'))
            .filter((i) => !i.code.endsWith('memmemo'))
            .map((memo) => {
              const foundMemo = memos.find((m) => m.eosCode === memo.code)
              if (foundMemo)
                return (
                  <ListItem
                    button
                    onClick={() => handleMemoOpen(foundMemo)}
                    key={memo.code}
                    className={classes.coolListThingy}
                  >
                    <Grid container alignItems="center">
                      <Grid xs={4}>
                        <Avatar
                          src={foundMemo.character.Avatar.OffsiteImage?.url || unknownUser}
                          style={{ width: '75px', height: '75px' }}
                        />
                      </Grid>
                      <Grid xs={8} style={{ lineHeight: '1.5rem' }}>
                        {foundMemo.Title}
                      </Grid>
                    </Grid>
                  </ListItem>
                )
            })}
        </List>
      )}
    </ContainerWithNavBar>
  )
}

export default Memos
