import { GameState } from '@meowwolf/react-platform-connection'
import { Strapi } from '@meowwolf/react-platform-connection/convergenceTypings/strapiTypings'

interface GetCompleteMemoryIdsProps {
  memories: readonly Strapi.Memory[]
  gameState?: GameState
}
type GetCompleteMemoryIds = (props: GetCompleteMemoryIdsProps) => Set<number>

/**
 * Return a set of IDs for all memories the user has completed.
 */
export const getCompleteMemoryIds: GetCompleteMemoryIds = ({ memories, gameState }) => {
  // Get an array of the user's mem IDs
  const userMemIdsArray = gameState?.user.inventory
    .filter((item) => item.configJson && item.configJson.model.name === 'mems')
    //@ts-expect-error - configJson is not typed in RPC yet
    .map((item) => item.configJson.model.id) as Array<number>

  // Loop through all memories and filter for those the user has completed
  const userMemIds = new Set(userMemIdsArray)
  const completeMemoryIds = memories
    .filter((memory) => {
      return memory.mems.every((mem) => {
        return userMemIds.has(mem.id)
      })
    })
    .map((memory) => memory.id)

  return new Set(completeMemoryIds)
}
