import React from 'react'

import { Grid, makeStyles } from '@material-ui/core'
import {
  MediaPlayer,
  DEFAULT_SIDEBAR_WIDTH_PX,
  GridItem,
  VerticallyCentered,
  BackgroundImage,
} from '@meowwolf/mw-denver-react'

interface Props {
  mediaSrc?: string
  color?: string
  type: 'memory' | 'mem'
  englishCaptionsURL?: string
  spanishCaptionsURL?: string
}

const VIDEO_ASPECT_RATIO = 100 / (16 / 9)
const VIDEO_WIDTH = DEFAULT_SIDEBAR_WIDTH_PX
const VIDEO_HEIGHT = (VIDEO_WIDTH * VIDEO_ASPECT_RATIO) / 100

const useStyles = makeStyles({
  rootStyle: {},
  memStyle: ({ color }: Props) => ({
    width: 128,
    height: 128,
    borderRadius: '50%',
    border: `5px solid ${color || ''}`,
  }),
  memoryStyle: {
    width: VIDEO_WIDTH,
    height: VIDEO_HEIGHT,
  },
})

/**
 * The media (image or video) associated with a Mem or Memory node.
 */
const MemoryInventorySideBarMedia: React.FC<Props> = ({
  mediaSrc,
  type,
  color,
  englishCaptionsURL,
  spanishCaptionsURL,
}) => {
  const { rootStyle, memStyle, memoryStyle } = useStyles({
    color,
    type,
    mediaSrc,
    englishCaptionsURL,
    spanishCaptionsURL,
  })
  const style = type === 'mem' ? memStyle : memoryStyle

  if (!mediaSrc) {
    return <></>
  }

  if (type === 'mem') {
    return (
      <VerticallyCentered className={rootStyle}>
        <Grid container xs={12} justifyContent="center" spacing={0}>
          <GridItem>
            <BackgroundImage src={mediaSrc} className={style} />
          </GridItem>
        </Grid>
      </VerticallyCentered>
    )
  }

  return (
    <MediaPlayer
      src={mediaSrc}
      aspectRatio={VIDEO_ASPECT_RATIO}
      image={{ size: 'contain' }}
      className={style}
      controls
      englishCaptionsURL={englishCaptionsURL}
      spanishCaptionsURL={spanishCaptionsURL}
    />
  )
}

export default MemoryInventorySideBarMedia
