import React, { createContext, FunctionComponent, useEffect, useRef, useState } from 'react'
import { BREAKPOINTS } from '../constants'

interface LayoutContextI {
  isMobile: boolean
}

export const LayoutContext = createContext<LayoutContextI>({
  isMobile: true,
})

export const testIsMobile = (): boolean => window && window.matchMedia(`(max-width:${BREAKPOINTS.md}px)`).matches

export const getWindowWidth = (): number => (typeof window !== 'undefined' ? window.innerWidth : 0)

const LayoutProvider: FunctionComponent = ({ children }) => {
  const [isMobile, setIsMobile] = useState(true)
  const screenWidth = useRef(getWindowWidth())

  useEffect(() => {
    const resize = (): void => {
      setIsMobile(testIsMobile())
      if (screenWidth.current === window.innerWidth) {
        return
      }
      screenWidth.current = window.innerWidth
    }
    resize()
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  }, [isMobile])

  return (
    <LayoutContext.Provider
      value={{
        isMobile: isMobile,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutProvider
