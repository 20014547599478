import isNumber from 'lodash/isNumber'
import { InventoryItem } from '@meowwolf/react-platform-connection'

/**
 * Return a set of Article IDs for all the articles in a user's inventory.
 */
export function getUserArticleIds(userInventory?: readonly InventoryItem[]): Set<number> {
  if (!userInventory) {
    return new Set() // Return empty set if inventory is undefined
  }

  // Get article IDs from user inventory
  const userArticleIds = userInventory
    .filter((item: InventoryItem) => item.configJson?.model.name === 'news-posts')
    .map((item) => item.configJson?.model.id)
    .filter(isNumber)

  return new Set(userArticleIds)
}
