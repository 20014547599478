import React from 'react'
import { Dialog, Grid, makeStyles } from '@material-ui/core'

import { Post } from './RecallBoards'
import RecallBoardsPost from './RecallBoards-Post'
import RecallBoardsGridItem from './RecallBoards-GridItem'
import { segment } from '../../utils/analytics'

interface Props {
  posts: ReadonlyArray<Post>
}

const useStyles = makeStyles({
  root: {
    marginTop: '12vh',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
})

/**
 * The 4-column grid of posts in the Recall Boards.
 * Also contains the modal dialog that contains the post detail view.
 */
const RecallBoardsGrid: React.FC<Props> = ({ posts }) => {
  const [activePostId, setActivePostId] = React.useState<number | undefined>(undefined)
  const activePost = posts.find((post) => post.id === activePostId)
  const onClose = () => {
    setActivePostId(undefined)
    segment.recallModalClose(activePost)
  }

  const onClick = (id: number) => {
    const activePost = posts.find((post) => post.id === id)
    segment.recallModalOpen(activePost)
    setActivePostId(id)
  }
  const classes = useStyles()

  // Get handlers for "next posting" and "previous posting" links
  const activePostIdx = activePostId !== undefined ? posts.findIndex((post) => post.id === activePostId) : undefined
  const hasNextPost = activePostIdx !== undefined ? activePostIdx < posts.length - 1 : false
  const hasPrevPost = activePostIdx !== undefined ? activePostIdx > 0 : false
  const nextPostId = activePostIdx !== undefined && hasNextPost ? posts[activePostIdx + 1].id : undefined
  const prevPostId = activePostIdx !== undefined && hasPrevPost ? posts[activePostIdx - 1].id : undefined

  // Todo: create separate segment events for "next posting" and "previous posting"?
  const onClickNextPost =
    nextPostId !== undefined
      ? () => {
          setActivePostId(nextPostId)
          segment.recallModalClose(activePost)
          const nextPost = posts.find((post) => post.id === nextPostId)
          segment.recallModalOpen(nextPost)
        }
      : undefined
  const onClickPrevPost =
    prevPostId !== undefined
      ? () => {
          setActivePostId(prevPostId)
          segment.recallModalClose(activePost)
          const prevPost = posts.find((post) => post.id === prevPostId)
          segment.recallModalOpen(prevPost)
        }
      : undefined

  return (
    <>
      {/* Posts grid */}
      <Grid container justifyContent="flex-start" className={classes.root}>
        <>
          {posts.map((post) => (
            <RecallBoardsGridItem key={post.id} post={post} onClickPost={onClick} />
          ))}
        </>
      </Grid>
      {/* Post modal */}
      <Dialog fullScreen open={activePostId !== undefined} onClose={onClose}>
        {activePost && (
          <RecallBoardsPost
            onClose={onClose}
            article={activePost}
            onClickNextPost={onClickNextPost}
            onClickPrevPost={onClickPrevPost}
          />
        )}
      </Dialog>
    </>
  )
}

export default RecallBoardsGrid
